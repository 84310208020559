import { SliceZone } from "@prismicio/react";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Container } from "@components/ui";
import { MainLayout } from "@layouts/MainLayout/MainLayout";
import { Content } from "@prismicio/client";
import { HowWork } from "@components/pages/home/HowWork";
import { usePromotionFooter } from "@middleware/hooks/usePromotionFooter";
import { components } from "../../../slices";
import { createClient } from "../../../prismicio";

type PageProps = {
  page: Content.PageAccueilDocument;
};

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: GetStaticPropsContext) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("page_accueil");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "validation",
        "header",
        "footer",
      ])),
      page,
    },
  };
};

const Home = ({ page }: PageProps) => {
  const { promotionFooterSlices } = usePromotionFooter(page);

  return (
    <Container className="page-cms home-page">
      {/* all bloc */}
      <SliceZone slices={page.data.slices} components={components} />
      <SliceZone slices={page.data.slices1} components={components} />
      <HowWork />
      <SliceZone slices={promotionFooterSlices} components={components} />
    </Container>
  );
};

Home.layout = MainLayout;
export default Home;
