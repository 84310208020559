import { useEffect, useState } from "react";
import { EGroupCustomer, ESegmentCustomer } from "@middleware/types";
import {
  BarPromotionSlice,
  PageAccueilDocument,
} from "../../../types.generated";
import { useCustomerSegment } from "./useCustomerSegment";
import { useCustomerGroup } from "./useCustomerGroup";

export const usePromotionFooter = (homePagePrismic: PageAccueilDocument) => {
  const { customerSegment } = useCustomerSegment();
  const { customerGroup } = useCustomerGroup();
  const [promotionFooterSlices, setPromotionFooterSlices] = useState<
    BarPromotionSlice[]
  >([]);

  useEffect(() => {
    const getCorrectSlices = () => {
      if (
        customerGroup === EGroupCustomer.NONE ||
        customerSegment === ESegmentCustomer.NONE
      ) {
        return [];
      }
      const groupSlices = homePagePrismic.data.slices4.filter(
        (slice) => slice.primary.group_code === customerGroup
      );
      if (groupSlices.length > 0) {
        return groupSlices;
      }

      const segmentSlices = homePagePrismic.data.slices3.filter(
        (slice) => slice.primary.segment === customerSegment
      );

      return segmentSlices;
    };

    setPromotionFooterSlices(getCorrectSlices());
  }, [
    customerGroup,
    customerSegment,
    homePagePrismic.data.slices3,
    homePagePrismic.data.slices4,
  ]);

  return {
    promotionFooterSlices,
  };
};
