import { GetStaticProps, GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { createClient } from "../../prismicio";
import Home from "./home";

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: GetStaticPropsContext) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("page_accueil");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "validation",
        "header",
        "footer",
      ])),
      page,
    },
  };
};

export default Home;
