import { Text } from "@components/ui";
import { Trans, useTranslation } from "next-i18next";
import style from "./How.module.scss";

const videoItems = [
  {
    url: "/static/images/home/PICTO_01_LARGE.mp4",
    titleTranslationKey: "home.choose",
    descriptionTranslationKey: "home.whatIChoose",
  },
  {
    url: "/static/images/home/PICTO_02_LARGE.mp4",
    titleTranslationKey: "home.receive",
    descriptionTranslationKey: "home.whatIReceive",
  },
  {
    url: "/static/images/home/PICTO_03_LARGE.mp4",
    titleTranslationKey: "home.enjoy",
    descriptionTranslationKey: "home.whatIEnjoy",
  },
  {
    url: "/static/images/home/PICTO_04_LARGE.mp4",
    titleTranslationKey: "home.continue",
    descriptionTranslationKey: "home.whatIContinue",
  },
];

export const HowWork = () => {
  const { t } = useTranslation();

  return (
    <section className="how">
      <div className={style.how}>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-blc-title-s1 center">
              <div className="splayer">
                <Text variant="span">{t("home.howItWorks")}</Text>
              </div>
            </div>
          </div>
        </div>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-flex space-between">
              {videoItems.map((item, index) => (
                <div className={style.item} key={index}>
                  <div className={style.feat}>
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      width={95}
                      height={68}
                    >
                      <source src={item.url} type="video/mp4" />
                    </video>
                  </div>
                  <Text variant="span">{t(item.titleTranslationKey)}</Text>
                  <Text variant="p">
                    <Trans
                      t={t}
                      i18nKey={item.descriptionTranslationKey}
                      components={{
                        br: <br />,
                      }}
                    />
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
